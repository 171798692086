<template>
  <div class="flex column center" style="margin-bottom:4rem;">
    <div class="new_box">
      <div class="titlee">全国首例！历思助力技术调查官参审污染环境案件，宣判啦~</div>
      <p class="time">2020.12.03</p>

      <p class="space_content" style="text-indent:0">
        近日，福建某法院对被告人黄某污染环境罪一案依法公开开庭审理并当庭宣判。 两名生态环境技术调查官首次出席参加庭审，成为全国首起 在生态环境诉讼中引入技术调查官制度的案件。
      </p>

      <div class="col8" style="margin:2rem auto">
        <img class="col8" v-lazy="require('../../assets/news/ls/35-1.png')">
      </div>

      <p class="space_content" style="text-indent:0;font-weight: 600">
        案件详情：
      </p>
      <p class="space_content" style="text-indent:0">
        2019年6月下旬至同年7月21日期间，被告人黄某在某村某制冰厂内，雇佣他人擅自进行煤油灯头、螺丝钉等金属表面加工处理。在加工处理过程中产生的含铬、镍等重金属废液、废渣，未经处理直接从车间排水沟进入沉淀池，再经塑料管道排往化粪池，后因塑料管道破损，废水无法流入化粪池而漫流入周边草地，造成铬、镍及pH值超标的废水排入外环境。
      </p>
      <p class="space_content" style="text-indent:0">
        法院为审理该案件，特委托
        <span style="font-weight: 600">
          福建历思司法鉴定
        </span>
        所对被告人黄某在此案中造成的
        <span style="font-weight: 600">
          生态环境损害价值
        </span>
        进行鉴定，并指派“漳州法院生态环境技术专家库”成员，闽南师范大学化学化工与环境学院的黄旭光、王振红两位教授作为本案技术调查官
      </p>
      <div class="col8" style="margin:2rem auto">
        <img class="col8" v-lazy="require('../../assets/news/ls/35-2.png')">
        <p class="center_">庭审现场</p>
      </div>

      <p class="space_content" style="text-indent:0">
        案件审理期间， 两位专家 多次到案发现场进行实地勘查、调查、取样等工作。 历思委派鉴定人出具了
        <span style="font-weight: 600">
          司法 鉴定意见书
        </span>

        并出庭作证。在庭审中，两位专家出具了技术调查意见，并对 历思的
        <span style="font-weight: 600">
          司法鉴定意见
        </span>
       的合理性及受污染地块修复的可行性和修复方案进行分析论证，认为历思的司法鉴定意见书内容客观公正、依据详实，可以作为审判的依据。最终，法院采信这一关键证据，对被告人黄某依法进行判决。
      </p>

      <p class="space_content" style="text-indent:0;text-align: center;font-weight: 600">
        法院审判：
      </p>
      <p class="space_content" style="text-indent:0">
        最终，法院以污染环境罪判处被告人黄某有期徒刑一年，缓刑二年，并处罚金人民币五千元，禁止被告人黄某自刑罚执行完毕之日起从事重金属加工相关职业，期限三年。
      </p>
      <p class="space_content" style="text-indent:0">
        法院同时向黄某发出“土壤净化令”，要求黄某在判决生效后严格按照与第三方签订的技术服务合同，保质保量完成对受污染地块土壤的净化修复。
      </p>
      <div class="col8" style="margin:2rem auto">
        <img class="col8" v-lazy="require('../../assets/news/ls/35-3.png')">

      </div>

      <p class="space_content" style="text-indent:0">
        据悉，该案系全国首例生态环境技术调查官参审的刑事案件，高效解决案件中涉及土壤污染认定及修复问题，在司法领域创新引入“植物富集”修复模式并首次发出“土壤净化令”，为生态修复多样化提供新样本。
      </p>

      <p class="space_content" style="text-indent:0;font-weight: 600">
        后记：
      </p>
      <p class="space_content" style="text-indent:0">
        环境侵权案件具有很强的专业性、技术性，对于污染物认定、损失评估、因果关系认定、环境生态修复方案等问题，通常需要从专业技术的角度作出评判。法院会委托具有专业知识及资质的司法
        <span style="font-weight: 600">鉴定机构</span>
        进行鉴定评估，邀请环境专家进行技术辅助，制作生态修复方案，共同推进审判工作顺利进行。
      </p>

    </div>
    <div class="flex wrap .col442" style="width:100%;text-align:left;margin-top:1rem">
      <div style="margin-bottom:1rem" class="col4 flex  start1">
        <div class="flex" style="margin-right:10%">
          <p style="white-space:nowrap">上一篇：</p>
          <router-link class="a" :to="'/news/lsnews/34'"><p class="ellipsis1">【官宣】“污水验毒”专用智慧采样器，硬核上线啦~</p>
          </router-link>
        </div>
      </div>
      <div style="margin-bottom:1rem" class="col4 flex  start1">
        <div class="flex">
          <p style="white-space:nowrap">下一篇：</p>
          <router-link class="a" :to="'/news/lsnews/36'"><p class="ellipsis1">浙江省禁毒总队、福建省禁毒总队领导一行莅临历思就“污水验毒”等技术进行考察交流！</p>
          </router-link>

        </div>
      </div>
      <div class="col2 flex  end1 center">
        <router-link class="a" style="color:#fff" :to="'/news/lsnews'">
          <el-button type="primary">返回列表</el-button>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      data: {}
    }
  },
}
</script>
<style>
.redText {
  background: red;
  color: white;
  font-size: 20px;
  font-weight: 600;
}

.titleText {
  font-size: 20px;
  font-weight: 600;
}
</style>
